// Contains style specific to a displaying canvas but not necessary for editor
@import "common";

.dev-right-click {
    pointer-events: auto !important;
}

.dev-click-suppress {
    pointer-events: none !important;
}

polyline {
    vector-effect: non-scaling-stroke;
}

p {
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

@keyframes kenburns_zoom_out {
    from {
        transform: scale(1.4);
    }
    to {
        transform: scale(1);
    }
}

@keyframes kenburns_zoom_in {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.4);
    }
}

@keyframes kenburns_pan_right {
    from {
        transform: translateX(10%) scale(1.2);
    }
    to {
        transform: translateX(-10%) scale(1.2);
    }
}

@keyframes kenburns_pan_left     {
    from {
        transform: translateX(-10%) scale(1.2);
    }
    to {
        transform: translateX(10%) scale(1.2);
    }
}

@keyframes element-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes element-fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes element-slide-in-right {
    from {
        opacity: 0;
        transform: translateX(20%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes element-slide-in-left {
    from {
        opacity: 0;
        transform: translateX(-20%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes element-slide-out-right {
    from {
        opacity: 1;
        transform: translateX(0%);
    }
    to {
        opacity: 0;
        transform: translateX(20%);
    }
}

@keyframes element-slide-out-left {
    from {
        opacity: 1;
        transform: translateX(0%);
    }
    to {
        opacity: 0;
        transform: translateX(-20%);
    }
}

@keyframes element-slide-in-down {
    from {
        opacity: 0;
        transform: translateY(20%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes element-slide-in-up {
    from {
        opacity: 0;
        transform: translateY(-20%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes element-slide-out-down {
    from {
        opacity: 1;
        transform: translateY(0%);
    }
    to {
        opacity: 0;
        transform: translateY(20%);
    }
}

@keyframes element-slide-out-up {
    from {
        opacity: 1;
        transform: translateY(0%);
    }
    to {
        opacity: 0;
        transform: translateY(-20%);
    }
}

@keyframes element-tick {
    0% {
        opacity: 0;
        transform: translate(0, 50%);
    }
    25% {
        opacity: 1;
        transform: translate(0, 0);
    }
    75% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, -50%);
    }
}

@keyframes element-pulse-opacity {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.33;
    }
    100% {
        opacity: 1;
    }
}

@keyframes element-pulse-blur {
    0% {
        stdDeviation: 0;
    }
    50% {
        stdDeviation: 15;
    }
    100% {
        stdDeviation: 0;
    }
}

@keyframes element-pulse-blink {
    0% {
        opacity: 1;
    }
    45% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes element-countdown-tick-enter {
    0% {
      opacity: 0;
      transform: translate(0, -15%);
    }
    20% {
      opacity: 1;
      transform: translate(0, 0);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
}

@keyframes element-countdown-tick-exit {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }
    80% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, 15%);
    }
}

@keyframes element-countdown-tick-cycle {
    0% {
      opacity: 0;
      transform: translate(0, -15%);
    }
    20% {
      opacity: 1;
      transform: translate(0, 0);
    }
    80% {
      opacity: 1;
      transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, 15%);
    }
}

.smart-slide-watermark {
    position: absolute;
    pointer-events: none;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
    }
}

.watermark {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 11px 16px 11px 16px;
    border-left: solid 1px rgba(0, 0, 0, 0.25);

    &.reverse {
        border-left: solid 1px rgba(255, 255, 255, 0.25);

        img {
            opacity: 0.85;
        }
    }

    &.no-footer {
        border-left: none;
    }

    img {
        opacity: 0.6;
        width: 110px !important;
    }
}

// these styles prevents clipping by long chart x-axis labels
.highcharts-root {
    overflow: visible;
}

.highcharts-container,
.highcharts-react-container {
    overflow: visible !important;
    position: absolute;
}

.highcharts-legend-nav-active {
    pointer-events: all;
}

.highcharts-legend-item {
    & > span {
        overflow: visible !important;
    }
}

.DashboardItem {
    #annotations > div {
        z-index: 5;
    }
    #connectors {
        z-index: unset !important;
        .startDecoration, .endDecoration {
            z-index: 5;
        }
    }
}

.dragdrop-target-box {
    position: absolute;
    background: transparentize($newBlue, 0.75);
    border: solid 1px $newBlue;
    opacity: 0.5;
}

// -----------------------------------------------------------------
// Canvas Panes
// -----------------------------------------------------------------
.slide_canvas {
    pointer-events: auto;
    overflow: hidden;
    background: white;
    position: absolute;
    box-sizing: border-box;
    transform-origin: 0% 0%;
    width: 100%;
    height: 100%;
    letter-spacing: 0px; //resets ui letterspacing set in body styles

    .spinner {
        pointer-events: none;
    }

    .spinner-shield {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        position: absolute;
    }

    .slide_click_shield {
        position: absolute;
        width: 100%;
        height: 100%;
        background: transparent;
    }
}

.slide_placeholder_canvas {
    background: transparent !important;
    box-shadow: none !important;
    overflow: visible !important;

    .CanvasBackground {
        display: none;
    }

    svg {
        overflow: visible;
    }
}

.fullscreen-click-shield {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
    z-index: 999999;
}

.baseline-font-checker {
    line-height: 1;

    .marker {
        width: 10px;
        height: 1px;
        background: red;
        vertical-align: baseline;
        display: inline-block;
    }
}

#text-block-placeholder-popup-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    pointer-events: none;
}

div.migrate-html-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99999999;
    font-size: 30px;
    color: white;
    background: black;
    // Set to 1 for debugging
    opacity: 0;

    .emphasized {
        // For easier debugging
        color: red;
    }
}

.clipText {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-superset {
    font-size: 0.5em;
    vertical-align: super;
}
